import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { colors, breakpoints } from "../style-utilities/variables";

const StyledImageCircle = styled.div`
  .img-circle {
    position: relative;
    margin: auto;
    width: 500px;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
  }

  @media (min-width: ${breakpoints.tablet}) {
    .img-circle {
      height: 50vw;
      width: 50vw;
      max-width: 600px;
      max-height: 600px;
    }
  }
`;

const ImageCircle = ({ img }) => {
  return (
    <StyledImageCircle>
      <div className="img-circle" style={{backgroundImage: `url(${img})`}}>
      </div>
    </StyledImageCircle>
  );
};

export default ImageCircle;
